body {
  background-color: #374855;
  color: #cdcdcd;
  font-family: 'Roboto', sans-serif;
}

* {
    box-sizing: border-box;
    border: 0;
    padding: 0;
    margin: 0;
}
#apg-h1-letter p {
    font-family: Roboto,sans-serif;
    color: #cdcdcd;
    font-size: 70px;
    font-weight: 700;
    letter-spacing: -2.5px;
    line-height: .8;
}

.theme-text-color {
    color: #ff9000;
}

.hr-gray {
    margin-top: 1px!important;
    margin-bottom: 1px!important;
    border: 0;
    border-top: 1px solid #afafaf;
}

.apd-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85vh;

}
a:hover {
  text-decoration: none;
  color: #cdcdcd;
}
